
import { defineComponent, ref, reactive, toRefs } from "vue";
import useSpreadTableSIBudgetReport from "@/hooks/Reporting/views/useSpreadTableSIBudgetReport";
import { usetableHeight } from "@/views/System/NSTGroupMSRP/NSTGroupMSRP/utils";
import useYear from "@/hooks/Reporting/useYear";
import useQM from "@/hooks/Reporting/useQM";
import useHandleCollapse from "@/hooks/Reporting/useHandleCollapse";
import useBuMakeTP, { BuOptionProps } from "@/hooks/Reporting/useBuMakeTP";
import useCategory from "@/hooks/Reporting/useCategory";
import moment from "moment";
import { useRoute } from "vue-router";
import useFindBu from "@/hooks/Reporting/useFindBu";
interface QueryParamsProps {
  bu: string[];
  modelCode: string[];
  typeClass: string[];
  make: string[];
}
export default defineComponent({
  name: "SI Budget Report",
  setup() {
    const route = useRoute();
    const programCode = ref();
    const { buList, findBu } = useFindBu();
    const queryParams = reactive<QueryParamsProps>({
      bu: [],
      modelCode: [],
      typeClass: [],
      make: ["CBU"],
    });
    const { year, openYear, handlerOpenChange, handlerPanelChange } = useYear();
    const { SHOW_PARENT, qmOptions, qm, getDefaultQm } = useQM();
    const { handleCollapse, isOpen } = useHandleCollapse();

    const { tableHeight } = usetableHeight("#ssHost");
    // 给bu赋默认值
    queryParams.bu = [findBu()];
    const {
      handleFocus,
      makeOptions,
      typeClassOptions,
      modelOptions,
      resetObj,
      buNameIdMap,
    } = useBuMakeTP<QueryParamsProps>(queryParams);

    const {
      incentiveCategory,
      programCategory,
      programCategoryOptions,
      incentiveCategoryOptions,
      handleFocusCategory,
      handleChangeCategory,
    } = useCategory([]);
    // 如果跳转过来的
    if (Object.keys(route.query).length > 0) {
      const params = Object.assign(
        queryParams,
        {
          period: route.query.year,
        },
        route.query
      );
      qm.value = [];
      qm.value = (route.query.qm as string).split(",");
      year.value = route.query.year as string;
      queryParams.bu = [route.query.bu] as string[];
      queryParams.typeClass = [];
      queryParams.typeClass.push(route.query.typeClass as string);
      queryParams.modelCode = [];
      if (route.query.model)
        queryParams.modelCode.push(route.query.model as string);
      queryParams.make = [];
      queryParams.make.push(route.query.make as string);
    }
    const getParams = () => {
      const _year =
        year.value && qm.value.length == 0
          ? ["Q1", "Q2", "Q3", "Q4"]
              .map((item) => `${year.value}.${item}`)
              .join(",")
          : qm.value.map((item) => `${year.value}.${item}`).join(",");
      const param = Object.assign({}, queryParams, {
        period: _year,
        programCode: programCode.value,
        incentiveCategory: incentiveCategory.value,
        programCategory: programCategory.value,
      });
      return param;
    };

    const { getFetch, handlerDownload } = useSpreadTableSIBudgetReport(
      getParams()
    );

    const handlerSearchClick = (): void => {
      const queryParam = getParams();
      getFetch(queryParam);
    };
    const handlerReset = (): void => {
      Object.assign(queryParams, resetObj);
      year.value = moment().add("month", 1).format("YYYY");
      qm.value = [getDefaultQm()];
      programCode.value = "";
      incentiveCategory.value = "";
      programCategory.value = "";
    };

    return {
      modelOptions,
      typeClassOptions,
      makeOptions,
      handleFocus,
      ...toRefs(queryParams),
      year,
      openYear,
      handlerOpenChange,
      handlerPanelChange,
      qm,
      qmOptions,
      handleCollapse,
      isOpen,
      handlerSearchClick,
      handlerReset,
      SHOW_PARENT,
      incentiveCategoryOptions,
      programCategoryOptions,
      incentiveCategory,
      programCategory,
      programCode,
      tableHeight,
      handleFocusCategory,
      handleChangeCategory,
      handlerDownload,
      buList,
    };
  },
});
