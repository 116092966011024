import { ref, computed } from "vue";
import { programSettingGetOfferType } from "@/API/setting";
interface OfferTypeProps {
  id: string;
  nameCn: string;
  nameEn: string;
  pathKey: string;
}
interface TableCellSelectProps {
  id: string;
  nameCn: string;
  nameEn: string;
  offerType: OfferTypeProps[];
}
const useCategory = (bu: string[]) => {
  const incentiveCategoryOptions = ref<any[]>([]);
  const programCategoryOptions = ref<any[]>([]);
  const incentiveCategory = ref();
  const programCategory = ref();

  const allPrograms = computed(() => {
    return incentiveCategoryOptions.value.flatMap((opt: any) => {
      return opt.offerType;
    });
  });

  const _getOfferType = () => {
    programSettingGetOfferType({ params: { buList: bu } }).then((res) => {
      incentiveCategoryOptions.value = res.incentiveCategory;
      const offerObj = incentiveCategoryOptions.value.find(
        (item: TableCellSelectProps) => item.id === incentiveCategory.value
      );
      console.log("offerObj", offerObj);
      programCategoryOptions.value = offerObj
        ? offerObj.offerType
        : allPrograms.value;
    });
  };
  const handleFocusCategory = (col: string) => {
    switch (col) {
      case "incentiveCategoryOptions":
        incentiveCategoryOptions.value = [];
        _getOfferType();
        break;
      case "programCategoryOptions":
        programCategoryOptions.value = [];
        if (incentiveCategoryOptions.value.length < 1) {
          _getOfferType();
        } else {
          const offerObj = incentiveCategoryOptions.value.find(
            (item: TableCellSelectProps) => item.id === incentiveCategory.value
          );
          programCategoryOptions.value = offerObj
            ? offerObj.offerType
            : allPrograms.value;
        }
        break;
      default:
        break;
    }
  };
  const handleChangeCategory = () => {
    programCategoryOptions.value = [];
    programCategory.value = "";
  };
  return {
    incentiveCategoryOptions,
    programCategoryOptions,
    incentiveCategory,
    programCategory,
    handleFocusCategory,
    handleChangeCategory,
  };
};
export default useCategory;
