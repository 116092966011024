import { ref } from 'vue'
import { TreeSelect } from 'ant-design-vue';
import moment from "moment";
const useQM = () => {
    interface TreeDataItem {
        value: string;
        key: string;
        title?: string;
        disabled?: boolean;
        children?: TreeDataItem[];
    }
    const SHOW_PARENT = TreeSelect.SHOW_PARENT;
    
    const qmOptions: TreeDataItem[] = [
        {
            title: 'Q1',
            value: 'Q1',
            key: 'Q1',
            children: [
                {
                    title: '01',
                    value: '01',
                    key: '01',
                },
                {
                    title: '02',
                    value: '02',
                    key: '02',
                },
                {
                    title: '03',
                    value: '03',
                    key: '03',
                },
            ],
        },
        {
            title: 'Q2',
            value: 'Q2',
            key: 'Q2',
            children: [
                {
                    title: '04',
                    value: '04',
                    key: '04',
                },
                {
                    title: '05',
                    value: '05',
                    key: '05',
                },
                {
                    title: '06',
                    value: '06',
                    key: '06',
                },
            ],
        },
        {
            title: 'Q3',
            value: 'Q3',
            key: 'Q3',
            children: [
                {
                    title: '07',
                    value: '07',
                    key: '07',
                },
                {
                    title: '08',
                    value: '08',
                    key: '08',
                },
                {
                    title: '09',
                    value: '09',
                    key: '09',
                },
            ],
        },
        {
            title: 'Q4',
            value: 'Q4',
            key: 'Q4',
            children: [
                {
                    title: '10',
                    value: '10',
                    key: '10',
                },
                {
                    title: '11',
                    value: '11',
                    key: '11',
                },
                {
                    title: '12',
                    value: '12',
                    key: '12',
                },
            ],
        },
    ];
    // 季度 月份, 
    const getDefaultQm = (): string => {
        return 'Q' + moment().add('month', 1).quarter();
    }
    const qm = ref<string[]>([getDefaultQm()]);
    return {
        SHOW_PARENT,
        qmOptions,
        qm,
        getDefaultQm
    }
}
export default useQM
