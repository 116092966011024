import { onMounted, onBeforeUnmount } from 'vue'
import "@grapecity/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css"
import GC from "@grapecity/spread-sheets"
import { useRouter } from 'vue-router'
import useHeaderTip from '@/hooks/Reporting/useHeaderTip'
import { HeaderProps, salesReport } from "@/API/report";
import { QueryParamsBudgetProps } from '@/views/Reporting/types'
import useExportExcel from '@/hooks/Reporting/useExportExcel'
export function initOutlineColumn(sheet: GC.Spread.Sheets.Worksheet) {
    sheet.outlineColumn.options({
        columnIndex: 0,
        showImage: true,
        showCheckBox: false,
        expandIndicator: require('@/assets/images/increaseIndicator.png'),
        collapseIndicator: require('@/assets/images/decreaseIndicator.png'),
        maxLevel: 5
    });
    sheet.showRowOutline(false);
    sheet.outlineColumn.refresh();
}
interface TableDataProps {
    programId: string;
    budgetId: string;
    overall: string;
    make: string;
    typeClass: string;
    model: string;
    incentiveCategory: string;
    programCategory: string;
    programCode: string;
    planAvgSi: string;
    planAvgSiPercent: string;
    actualAvgSi: string;
    actualAvgSiPercent: string;
    gapAvgSi: string;
    gapAvgSiPercent: string;
    summaryType?: string;
    textIndent: number;
}
const headerData = [
    {name: 'overall', size: '2*'}, 
    {name: 'make', size: 100}, 
    {name: 'typeClass', size: 100},
    {name: 'model', size: 150},
    {name: 'incentiveCategory', size: 140},
    {name: 'programCategory', size: 140},
    {name: 'programCode', size: 140},
    {name: 'planAvgSi', size: '*'},
    {name: 'planAvgSiPercent', size: '*'},
    {name: 'actualAvgSi', size: '*'},
    {name: 'actualAvgSiPercent', size: '*'},
    {name: 'gapAvgSi', size: '*'},
    {name: 'gapAvgSiPercent', size: '*'},
]
export const setCollapsed = (sheet: GC.Spread.Sheets.Worksheet, data: TableDataProps[], isCollapsed: boolean, level = 1) => {
    sheet.suspendPaint();
    //强制折叠所有二级
    for (let row = 0; row < data.length; row++) {
        if (data[row].textIndent === level) {
            sheet.rowOutlines.setCollapsed(row, isCollapsed);
        }
    }
    sheet.outlineColumn.refresh();
    sheet.resumePaint();
}
const useSpreadTableSIBudgetReport = (queryParams: QueryParamsBudgetProps) => {
    const router = useRouter()
        let workbook: GC.Spread.Sheets.Workbook;
        // 头部
        const initHeader = (spread: GC.Spread.Sheets.Workbook, sheet: GC.Spread.Sheets.Worksheet, firstLeft: HeaderProps[], secondLeft: HeaderProps[]) => {
            //挂起
            sheet.suspendPaint();
            // 两个YTD, 4个Q, 1月到12月
            const firstRight = [
                {name: 'Plan'}, {name: 'Plan'}, {name: 'Actual'}, {name: 'Actual'}, {name: 'GAP', formal: 'GAP=Actual - Plan'}, {name: 'GAP', formal: 'GAP=Actual - Plan'}
            ]
            const secondRight = [
               {name: 'AVG. SI'}, {name: '% AVG. SI'}, {name: 'AVG. SI'}, {name: '% AVG. SI'}, {name: 'AVG. SI', formal: 'GAP=Actual - Plan'}, {name: '% AVG. SI', formal: 'GAP=Actual - Plan'}
            ]
            const headerArr: HeaderProps[][] = [
                [...firstLeft, ...firstRight],
                [...secondLeft, ...secondRight]
            ]
            
            // 设置表头为两行
            sheet.setRowCount(2, GC.Spread.Sheets.SheetArea.colHeader);
            
            for (let row = 0; row < headerArr.length; row++) {
                for (let col = 0; col < headerArr[row].length; col++) {
                    // 给表头单元格赋值
                    sheet.setValue(row, col, headerArr[row][col].name, GC.Spread.Sheets.SheetArea.colHeader);
                    // 设置表头单元格的背景色 
                    sheet.getCell(row, col, GC.Spread.Sheets.SheetArea.colHeader).backColor('#bfbfbf')
                    // 设置行高
                    sheet.setRowHeight(row, 30, GC.Spread.Sheets.SheetArea.colHeader);
                }
            }
            const range = new GC.Spread.Sheets.Range(-1, -1, -1, -1);
            sheet.autoMerge(range, GC.Spread.Sheets.AutoMerge.AutoMergeDirection.rowColumn, GC.Spread.Sheets.AutoMerge.AutoMergeMode.free, GC.Spread.Sheets.SheetArea.colHeader);
            sheet.autoMerge(range, GC.Spread.Sheets.AutoMerge.AutoMergeDirection.column, GC.Spread.Sheets.AutoMerge.AutoMergeMode.free, GC.Spread.Sheets.SheetArea.rowHeader);

            // 冻结，固定前面4列
            sheet.frozenColumnCount(firstLeft.length)
            sheet.setColumnCount(headerArr[0].length, GC.Spread.Sheets.SheetArea.viewport)

            // 设置headerTip
            useHeaderTip(sheet, headerArr, '#ssHost', 150)

            spread.resumePaint();
        }
        // Spread初始化设置
        const initSpread = (spread: GC.Spread.Sheets.Workbook, sheet: GC.Spread.Sheets.Worksheet) => {
            sheet.suspendPaint();
            
            // sheet是否显示
            spread.options.tabNavigationVisible = false
            spread.options.tabStripVisible = false

            // 隐藏行头
            sheet.options.rowHeaderVisible = false;

            // 要设置允保护，不允许的才能生效, 不可编辑
            // sheet.options.isProtected = true;
            const style = sheet.getDefaultStyle();
            sheet.defaults.rowHeight = 30;
            style.vAlign = GC.Spread.Sheets.VerticalAlign.center;

            sheet.getCell(-1, 7).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
            sheet.getCell(-1, 8).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
            sheet.getCell(-1, 9).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
            sheet.getCell(-1, 10).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
            sheet.getCell(-1, 11).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
            sheet.getCell(-1, 12).hAlign(GC.Spread.Sheets.HorizontalAlign.right);

            spread.resumePaint();
        }
        
        const bindDataSpread = (spread: GC.Spread.Sheets.Workbook, sheet: GC.Spread.Sheets.Worksheet, data: TableDataProps[]) => {
            sheet.suspendPaint();
            // 设置行数
            sheet.setRowCount(data.length, GC.Spread.Sheets.SheetArea.viewport);
            // 自动生成绑定列
            sheet.autoGenerateColumns = false;
            // 绑定数据源
            sheet.setDataSource(data);
            // 将数据字段绑定到指定列size,formatter,cellType
            sheet.bindColumns(headerData);
                        
            for (let row = 0; row < data.length; row++) {
                // 展开收起
                if ('textIndent' in data[row]) { 
                    sheet.getCell(row, 0).textIndent(data[row].textIndent)
                }
                // 汇总行，设置背景色
                if ('summaryType' in data[row]) sheet.getCell(row, -1).backColor('#D4E6F1');
                for (let col = 0; col < headerData.length; col++) {
                    // 因为前面有固定的6列，索引是5.所以后面有链接是索引为奇数的，并且，不是汇总行的
                    if (col > 5 && col % 2 !== 0 && !data[row].summaryType && sheet.getValue(row, col) !== '-') {
                        // 设置跳转
                        sheet.setHyperlink(row, col, {
                            url: '',
                            command: function (sheet) {
                                const routeUrl = router.resolve({ path: '/budgutinputcontrolling', query: {
                                    pgmId: data[row].programId as string
                                }})
                                window.open(routeUrl.href, '_blank');
                            }
                        })
                        continue;
                    }
                }
            }
            sheet.resumePaint();
        }
        // 挂载到html上
        const ssHostInit = (spread: GC.Spread.Sheets.Workbook, sheet: GC.Spread.Sheets.Worksheet, firstLeft: HeaderProps[], secondLeft: HeaderProps[], data: TableDataProps[]) => {
            initHeader(spread, sheet, firstLeft, secondLeft)
            initSpread(spread, sheet)
            bindDataSpread(spread, sheet, data)
        }
        const init = (data: any) => {
            workbook && workbook.destroy()
            const secondLeft = [{name: ''}, {name: 'Make'}, {name: 'Type Class'}, {name: 'Model'}, {name: 'Incentive Category'}, {name: 'Program Category'}, {name: 'Program Code'}]
            const firstLeft = [{name: ''}, {name: 'Make'}, {name: 'Type Class'}, {name: 'Model'}, {name: 'Incentive Category'}, {name: 'Program Category'}, {name: 'Program Code'}]
            const spread = new GC.Spread.Sheets.Workbook(document.getElementById('ssHost') as HTMLElement);
            workbook = spread
            const sheets = spread.getActiveSheet();
            ssHostInit(spread, sheets, firstLeft, secondLeft, data);
            // 
            if (data.length > 0) initOutlineColumn(sheets);
            // initOutlineColumn之后调用用setCollapsed
            setCollapsed(sheets, data, true, 0)
            setCollapsed(sheets, data, true, 1)
            setCollapsed(sheets, data, true, 2)
            setCollapsed(sheets, data, true, 3)
        }
        const getFetch = (queryParams: QueryParamsBudgetProps) => {
            salesReport(queryParams).then(res => {
                init(res)
            })
        }
        const handlerDownload = () => {
            useExportExcel(workbook, 'SI Budget Report')
        }
        onMounted(() => {
            getFetch(queryParams)
        })

        onBeforeUnmount(() => {
            workbook.destroy();
        })
        return {
            handlerDownload,
            getFetch
        }
}
export default useSpreadTableSIBudgetReport