import { fetchGet, fetchPost, fetchPut, fetchDelete } from './index'
import { AttachementProps } from '@/views/Program/types'
import { string } from 'vue-types';

export interface LoadAttachmentProps {
    pageAt: number;
    pageSize: number;
    tableCell: {[key: string]: string}[];
    tableCol: {id: string; display: string; type: string}[];
    totalCount: number;

}
export interface OBProps {
    id: string; 
    name: string;
}
export interface OBBProps {
    label: string; 
    value: string;
}
export interface LoadCommentsTableCellProps{
    bu?: OBProps;
    cn?: OBProps;
    customerType?: OBProps[];
    dealerType?: OBProps[];
    id: string;
    incentiveCategory?: OBProps;
    offerType: OBProps[];
    position?: OBProps;
    powerTrain?: OBProps[];
    preconditionType?: OBProps;
}
export interface LoadCommentsProps{
    pageAt: number;
    pageSize: number;
    tableCell: LoadCommentsTableCellProps[];
    tableCol: {id: string; display: string; type: string}[];
    totalCount: number;
}

export interface AttachementListProps {
    docId: string;
    docName: string;
    docPeriod: string;
    docUrl: string;
}
// 查询最新PDI List
export const settingSearchPdi = (params = {}): Promise<any> => {
    return fetchGet('/pgapi/program/setting/search_pdi', params)
}

// Attachment Master Setting Overview
export const loadAttachment = (params = {}): Promise<LoadAttachmentProps> => {
    return fetchGet('/pgapi/program/setting/load_attachment', params)
}

export const programSettingGetOfferType = (params = {}): Promise<{incentiveCategory: []}> => {
    return fetchGet('/pgapi/program/setting/get_offer_type', params)
}
export const deleteAttachment = (params = {}): Promise<LoadAttachmentProps> => {
    return fetchGet('/pgapi/program/setting/delete_attachment', params)
}

// Get program attachment setting
export const getAttachmentSetting = (params = {}): Promise<AttachementProps[]> => {
    return fetchPost('/pgapi/program/design/get_attachment_setting', params)
}

export const importProgramAttachment = (params = {}): Promise<AttachementProps[]> => {
    return fetchPost('/pgapi/program/design/import_program_attachment', params)
}

export const downloadProgramAttachment = (params = {}): Promise<AttachementProps[]> => {
    return fetchPost('/pgapi/program/design/download_program_attachment', params)
}
export const deleteProgramAttachment = (params = {}, data: string): Promise<AttachementProps[]> => {
    return fetchPost('/pgapi/program/design/delete_program_attachment?programId=' + data, params)
}


export const saveAttachment = (params = {}): Promise<AttachementProps[]> => {
    return fetchPost('/pgapi/program/setting/save_attachment', params)
}

export const importAttachment = (params = {}): Promise<AttachementProps[]> => {
    return fetchPost('/pgapi/program/setting/import_attachment', params)
}
export const loadCommentsMaster = (params = {}): Promise<LoadCommentsProps> => {
    return fetchPost('/pgapi/program/setting/load_comments_master', params)
}

export const getProgramAttachment = (params = {}): Promise<{attachment: AttachementListProps[]; programId: string}> => {
    return fetchGet('/pgapi/program/data/get_program_attachment', params)
}


export const commentsSearchOption = (params = {}): Promise<OBBProps[]> => {
    return fetchPost('/pgapi/program/setting/comments_search_option', params)
}
export const storeCommentsMaster = (params = {}): Promise<OBBProps[]> => {
    return fetchPost('/pgapi/program/setting/store_comments_master', params)
}

export const deleteCommentsMaster = (params = {}): Promise<{incentiveCategory: []}> => {
    return fetchGet('/pgapi/program/setting/delete_comments_master', params)
}



